<template>
  <router-link v-if="column.link" :to="column.link + '/' + item.id">{{ column.value(item) }}</router-link>
  <render v-else-if="column.columnCode === 'action'" :data="item" :render="column.value"/>
  <render v-else-if="column.hasRender" :data="item" :render="column.value"/>
  <span v-else>{{ column.value(item) }}</span>
</template>

<script>

export default {
  name: 'TableCell',
  props: {
    column: {
      type: Object,
      required: true
    },
    item: {
      type: null,
      required: true
    }
  }
}
</script>

<style>

</style>
