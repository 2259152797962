<template>

  <tbody>
  <tr v-for="(row, index) in list" :key="index">
    <td class="has-text-centered" v-for="(column, idx) in columnConfig" :key="idx">
      <table-cell :column="column" :item="row"></table-cell>
    </td>
  </tr>
  </tbody>
</template>

<script>

    import {library} from '@fortawesome/fontawesome-svg-core'
    import {
        faCloudDownloadAlt,
        faEye,
        faMinusSquare,
        faPencilAlt,
        faPlusSquare,
        faTrashAlt
    } from '@fortawesome/free-solid-svg-icons'
    import TableCell from './TableCell.vue'

    library.add([
  faMinusSquare, faPlusSquare, faEye, faPencilAlt, faTrashAlt, faCloudDownloadAlt
])

export default {
  name: 'TableBody',
  components: {TableCell},
  props: {
    list: {
      type: Array,
      required: true
    },
    columnConfig: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      row: null,
      button: null
    }
  },
  computed: {
  },
  methods: {
    clicked (row, column) {
      if (column.meta.clickable) {
        this.$emit('clicked', {column, row})
      }
    }
  }
}

</script>
