<template>
  <thead>
  <tr class="has-background-light  is-narrow is-hoverable is-striped is-bordered">
    <th class="vue-table-header has-text-centered"
        v-for="(column, index) in columnConfig"
        :style="{'width: 50px;': column.columnCode === 'action'}"
        :key="index">
      <template v-if="column.columnCode === 'action'">
        <span>{{ column.columnName }}</span>
      </template>
      <template v-else>
        <span style="cursor: pointer;" @click="toggleSort(column.columnCode)">
          {{ column.columnName }}
        </span>
        <span class="table-header-controls">
          <span class="sorter"
                @click="toggleSort(column.columnCode)">
              <span class="icon is-small">
                  <fa :icon="sortIcon(column.columnCode)" size="1x"></fa>
              </span>
          </span>
          <fa v-if="column.columnCode === sortField"
              @click="clearColumnSort"
              icon="trash-alt"
              size="1x"></fa>
        </span>
      </template>
    </th>
  </tr>
  </thead>
</template>

<script>
  import {library} from '@fortawesome/fontawesome-svg-core'
  import {faSort, faSortDown, faSortUp, faTrashAlt} from '@fortawesome/free-solid-svg-icons'

  library.add(faSort, faSortUp, faSortDown, faTrashAlt)

export default {
  name: 'TableHeader',
  props: {
    columnConfig: {
      type: Array,
      required: true
    },
    sortField: {
      type: String,
      required: true
    },
    order: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      pageSelected: false
    }
  },
  methods: {
    sortIcon (columnCode) {
      if (this.sortField === columnCode) {
        return this.order === 0 ? faSortUp : faSortDown
      }
      return faSort
    },
    toggleSort (columnCode) {
      this.$emit('sort-update', columnCode)
    },
    clearColumnSort () {
      this.$emit('sort-update', '')
    }
  }
}
</script>

<style scoped>

  th.vue-table-header {
    white-space: nowrap;
    align-content: center;
  }

  th.vue-table-header .table-header-controls sorter {
    cursor: pointer;
    opacity: 0.5;
  }

  th.vue-table-header .table-header-controls :hover {
    opacity: 1;
  }


</style>
