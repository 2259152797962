<template>
  <div class="d-flex justify-content-end align-items-center py-3 px-3">
    <div class="column">
      <div>
        {{ info }}
      </div>
    </div>
    <div class="column is-narrow has-text-right">
      <b-pagination size="md"
                    :total-rows="totalCount"
                    v-model="currentPage"
                    :per-page="limit"
                    @change="changePage"
                    :disabled="hasCount || loading">
      </b-pagination>
    </div>
  </div>

</template>

<script>

export default {
  name: 'TableFooter',
  props: {
    start: {
      type: Number,
      required: true
    },
    totalCount: {
      type: Number,
      required: true
    },
    limit: {
      type: Number,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      currentPage: (this.start / this.limit) + 1
    }
  },
  computed: {
    hasCount () {
      return this.totalCount <= this.limit
    },
    hasData () {
      return this.totalCount > 0
    },
    startInfo () {
      return this.hasData ? ((this.start - 1) * this.limit) + 1 : 0
    },
    totalInfo () {
      return this.totalCount < this.start * this.limit
    },
    pageInfo () {
      return this.hasData ? (this.totalInfo ? this.totalCount : this.start * this.limit) : 0
    },
    info () {
      return `From ${this.startInfo} to ${this.pageInfo} of ${this.totalCount} `
    }
  },
  methods: {
    changePage (page) {
      if (this.start === page) return
      this.$emit('change-pagination', page)
    }
  }
}
</script>

<style scoped>

</style>
